import React from 'react';
import Navigation from "../components/Navigation";
import Sidebar from "../components/Sidebar";
import ProfileContainer from "../components/ProfileContainer";
import {connect} from "react-redux";
import {dialogsActions} from "../redux/actions";
import {withRouter} from "react-router";

const Profile = ({user}) => {
    if (user == null) {
        return "";
    }

    return (
        <>
            <div className="profile-tab-open">
                <div className="main-layout">
                    <Navigation/>
                    <Sidebar user={user}/>
                    <main className="main">
                        <ProfileContainer user={user}/>
                    </main>
                </div>
            </div>
        </>
    );
};

export default withRouter(
    connect(
        ({user}) => ({user: user.data}),
        dialogsActions,
    )(Profile),
);

