import React from 'react';
import * as Yup from 'yup';
import {Formik} from 'formik';
import store from '../redux/store';
import {userActions} from '../redux/actions';


const Auth = () => {

    const schema = Yup.object({
        email: Yup.string().required(),
        password: Yup.string().required(),
    });

    const handleSubmit = (values, {setSubmitting, props}) => {
        store
            .dispatch(userActions.fetchUserLogin(values))
            .then(({status}) => {
                if (status === 'success') {
                    //props.history.push('/');
                    window.location = '/';
                }else{
                    console.log("error");
                }
                setSubmitting(false);
            })
            .catch((error) => {
                console.log(error);
                setSubmitting(false);
            });
    };

    return (
        <>
            <div className="main-layout card-bg-1">
                <div className="container d-flex flex-column">
                    <div className="row no-gutters text-center align-items-center justify-content-center min-vh-100">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                            <h1 className="font-weight-bold">Авторизация</h1>
                            <p className="text-dark mb-3"/>
                            <Formik
                                validationSchema={schema}
                                onSubmit={handleSubmit}
                                initialValues={{
                                    email: '',
                                    password: '',
                                }}
                            >
                                {({
                                      handleSubmit,
                                      handleChange,
                                      values,
                                  }) => (
                                    <form className="mb-3" noValidate onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="email" className="sr-only">Электрон почта</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-md"
                                                id="email"
                                                name="email"
                                                placeholder="Электрон почтани киритинг"
                                                value={values.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password" className="sr-only">Парол</label>
                                            <input
                                                type="password"
                                                className="form-control form-control-md"
                                                id="password"
                                                name="password"
                                                placeholder="Паролни киритинг"
                                                value={values.password}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <button className="btn btn-primary btn-lg btn-block text-uppercase font-weight-semibold" type="submit">Кириш</button>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Auth;
