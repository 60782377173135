import axios from "axios";
import Const from "./Const";
import {NotificationManager} from "react-notifications";

axios.defaults.baseURL = Const.URL;

axios.defaults.headers.common["token"] = window.localStorage.token;

window.axios = axios;

axios.interceptors.response.use(function (response) {

    let data = response.data;

    if(data.status && data.status === "error"){
        NotificationManager.error(data.message, "Error");
    }

    return response;
}, function (error) {
    if(error.response) {
        if (403 === error.response.status && localStorage.getItem("token").length) {
            localStorage.clear();
            window.location = '/';
        } else {
            console.log("interceptors", error);
            return Promise.reject(error);
        }
    }else{
        // localStorage.clear();
        // window.location = '/';
    }
});

export default axios;
