import {axios} from "../../core";

export default {
    getAll: () => axios.get("/dialogs"),
    getArchives: () => axios.get("/dialogs?archive=1"),
    search: (search) => axios.get("/search", {params: {"query": search}}),
    create: ({partner, text}) => axios.post("/dialogs", {partner, text}),
    confirmed: ({dialog_id}) => axios.put(`/user/dialogs/approve/${dialog_id}`),
    close: ({dialog_id, child_category_id}) => axios.put(`/user/dialogs/close/${dialog_id}`, {child_category_id: child_category_id}),
    transfer: ({dialog_id, category_id}) => axios.put(`/operator/dialogs/transfer/${dialog_id}`, {category_id: category_id}),
    createDialog: ({category_id, author, text}) => axios.post(`/operator/dialogs/create`, {category_id: category_id, author_id: author, text: text}),
    setCategory: ({dialog_id, category_id}) => axios.put(`/user/dialogs/child/${dialog_id}`, {category_id: category_id}),
    getSelector: () => axios.get("selector")
};
