import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import DialogItem from "./DialogItem";
import {dialogsActions} from "../redux/actions";
import socket from '../core/socket';

const Dialogs = ({fetchDialogs, fetchSearch, fetchArchive, updateReadedStatus, currentDialogId, items, userId}) => {

    const [unreadCount, setUnreadCount] = useState(-1);

    window.fetchDialogs = fetchDialogs;

    useEffect(() => {
        let total = items.reduce((a, b) => a + (b.total_unreads || 0), 0);

        if (total === 0) {
            setUnreadCount(0);
        }

        if (unreadCount < total) {
            if (unreadCount !== -1) {
                //playIncomingMessageSound();
            }
            setUnreadCount(total);
        }
    }, [items]);

    useEffect(() => {
        fetchDialogs();
        socket.on('SERVER:DIALOG_CREATED', fetchDialogs);
        socket.on('SERVER:NEW_MESSAGE', fetchDialogs);
        socket.on('SERVER:MESSAGES_READED', updateReadedStatus);
        return () => {
            socket.removeListener('SERVER:DIALOG_CREATED', fetchDialogs);
            socket.removeListener('SERVER:NEW_MESSAGE', fetchDialogs);
        };
    }, []);

    const handleSearch = (e) => {
        let text = e.target.value;

        if (text.length > 0) {
            fetchSearch(e.target.value);
        } else {
            fetchDialogs();
        }
    }

    const handleType = e => {
        let type = parseInt(e.target.value);
        if(type === 1){
            fetchDialogs();
        }else{
            fetchArchive();
        }
    }

    return (
        <>
            <aside className="sidebar">
                <div className="tab-content">
                    <div className="tab-pane active" id="chats-content">
                        <div className="d-flex flex-column h-100">
                            <div className="hide-scrollbar h-100" id="chatContactsList">
                                <div className="sidebar-header sticky-top p-2">
                                    <div className="sidebar-sub-header">
                                        <div className="input-group">
                                            <select onChange={handleType} className="form-control search border-right-0 transparent-bg pr-0">
                                                <option value="1">Фаол</option>
                                                <option value="2">Архив</option>
                                            </select>
                                        </div>
                                        <div className="input-group">
                                            <input type="text" onChange={handleSearch} className="form-control search border-right-0 transparent-bg pr-0" placeholder="Излаш..."/>
                                            <div className="input-group-append">
                                                <div className="input-group-text transparent-bg border-left-0" role="button">
                                                    <svg className="text-muted hw-20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul className="contacts-list" id="chatContactTab" data-chat-list="">
                                    {
                                        items ? items.map(item => <DialogItem currentDialogId={currentDialogId} item={item} key={Math.random()}/>) : ""
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    );
};

export default connect(
    ({dialogs}) => dialogs,
    dialogsActions,
)(Dialogs);
