import {userApi} from '../../utils/api';

const Actions = {
    setUserData: data => ({
        type: 'USER:SET_DATA',
        payload: data,
    }),
    setIsAuth: bool => ({
        type: 'USER:SET_IS_AUTH',
        payload: bool,
    }),
    fetchUserData: () => dispatch => {
        userApi
            .getMe()
            .then(({data}) => {
                dispatch(Actions.setUserData(data.user));
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 404) {
                        localStorage.removeItem('token');
                    }
                }
                console.log(err.toString());
            });
    },
    fetchUserLogin: postData => dispatch => {
        return userApi
            .signIn(postData)
            .then(({data}) => {
                const {token} = data;
                window.axios.defaults.headers.common['token'] = token;
                window.localStorage['token'] = token;
                dispatch(Actions.fetchUserData());
                dispatch(Actions.setIsAuth(true));
                return data;
            })
            .catch(({response}) => {
                console.log(response.toString())
            });
    },
};

export default Actions;
