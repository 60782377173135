import React from 'react';
import {format, isToday, isValid} from 'date-fns';
import Avatar from "./Avatar";
import {Link} from "react-router-dom";
import classNames from "classnames";

const DialogItem = ({item, currentDialogId}) => {

    const getMessageTime = date => {
        date = new Date(date);

        if (!isValid(date)) {
            return ""
        }
        if (isToday(date)) {
            return format(date, 'HH:mm');
        } else {
            return format(date, 'd.MM.Y HH:mm');
        }
    };

    let active = parseInt(currentDialogId) === item.id ? "active" : "";

    if (item.is_closed) {
        active = "dialog-closed";
    }

    return (
        <>
            <li className={classNames("contacts-item", active)}>
                <Link to={`/dialog/${item.id}`} className={"contacts-link"}>
                    <Avatar user={item.author}/>
                    <div className="contacts-content">
                        <div className="contacts-info">
                            <h6 className="chat-name">{item.author.last_name}</h6>
                            <div className="chat-time">{getMessageTime(item.last_message.created_at)}</div>
                        </div>
                        <div className="contacts-texts">
                            <p className="text-truncate">{item.last_message.text}</p>
                            {
                                active === "dialog-closed" ?
                                    <div className="d-inline-flex align-items-center ml-1">
                                        <svg className="hw-16 text-muted" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd"/>
                                        </svg>
                                    </div>
                                    : item.total_unreads > 0 ? <div className="badge badge-rounded badge-primary ml-1">{item.total_unreads}</div> : ""
                            }
                        </div>
                    </div>
                </Link>
            </li>
        </>
    );
};

export default DialogItem;
