import React, {useEffect, useRef} from 'react';
import Avatar from "./Avatar";
import {connect} from 'react-redux';
import find from 'lodash/find';

import {messagesActions} from '../redux/actions';
import socket from '../core/socket';
import BaseMessages from "./BaseMessages";

const Messages = ({currentDialog, fetchMessages, addMessage, items, user, isLoading}) => {

    const messagesRef = useRef(null);

    const onNewMessage = data => {
        addMessage(data);
    };

    useEffect(() => {
        if (currentDialog) {
            fetchMessages(currentDialog.id);
        }

        socket.on('SERVER:NEW_MESSAGE', onNewMessage);

        return () => socket.removeListener('SERVER:NEW_MESSAGE', onNewMessage);
    }, [currentDialog]);


    useEffect(() => {
        if (messagesRef.current != null) {
            messagesRef.current.scrollTo(0, 999999);
        }
    }, [items]);

    if (user == null) {
        return "";
    }

    if (!currentDialog) {
        return (<>
            <main className="main">
                <div className="chats">
                    <div className="d-flex flex-column justify-content-center text-center h-100 w-100">
                        <div className="container">
                            <Avatar user={user} type="lg"/>
                            <h5 className={"mt-2"}>Welcome, {user.last_name} {user.first_name}!</h5>
                        </div>
                    </div>
                </div>
            </main>
        </>);
    }

    return (
        <BaseMessages
            user={user}
            blockRef={messagesRef}
            items={items}
            isLoading={isLoading && !user}
            currentDialog={currentDialog}
            partner={
                currentDialog.author
            }
        />
    )
};

export default connect(
    ({dialogs, messages, user, attachments}) => ({
        currentDialog: find(dialogs.items, {id: parseInt(dialogs.currentDialogId)}),
        items: messages.items,
        isLoading: messages.isLoading,
        attachments: attachments.items,
        user: user.data,
    }),
    messagesActions,
)(Messages);
