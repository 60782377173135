import React from 'react';
import {Formik} from "formik";
import Yup from "../utils/YupLocale";
import {Form} from "react-bootstrap";
import {axios} from "../core";

const ProfileContainer = ({user}) => {

    const schema = Yup.object({
        last_name: Yup.string().required().label("Фамилия"),
        first_name: Yup.string().required().label("Исм"),
        middle_name: Yup.string().required().label("Шариф"),
    });

    const handleSubmit = (values, {setSubmitting}) => {
        setSubmitting(true);

        let params = {
            last_name: values.last_name,
            first_name: values.first_name,
            middle_name: values.middle_name,
        };

        axios.put(`/user/${user.id}`, params).then(res => {
            setSubmitting(false);
            window.location = "/profile";
        }).catch(e => {
            setSubmitting(false);
            window.location = "/profile";
        });
    }

    if (user == null) {
        return "";
    }

    return (
        <>
            <div className="profile">
                <div className="page-main-heading sticky-top py-2 px-3 mb-3">
                    <div className="pl-2 pl-xl-0">
                        <h5 className="font-weight-semibold">Созлашлар</h5>
                        <p className="text-muted mb-0">Маълумотларни ўзгартиришингиз мумкин</p>
                    </div>
                </div>

                <div className="container-xl px-2">
                    <div className="row">
                        <div className="col">
                            <Formik
                                validationSchema={schema}
                                onSubmit={handleSubmit}
                                enableReinitialize={true}
                                initialValues={{
                                    last_name: user.last_name,
                                    first_name: user.first_name,
                                    middle_name: user.middle_name,
                                }}
                            >
                                {({
                                      handleSubmit,
                                      handleChange,
                                      values,
                                      errors,
                                      touched,
                                  }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <div className="card mb-3">
                                            <div className="card-header">
                                                <h6 className="mb-1">Профиль</h6>
                                            </div>

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-12 col-12">
                                                        <Form.Group controlId="validationFormik-last_name">
                                                            <Form.Label>{"Фамилия"}</Form.Label>
                                                            <Form.Control
                                                                name="last_name"
                                                                value={values.last_name}
                                                                onChange={handleChange}
                                                                isInvalid={touched.last_name && !!errors.last_name}/>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.last_name}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-12 col-12">
                                                        <Form.Group controlId="validationFormik-first_name">
                                                            <Form.Label>{"Исм"}</Form.Label>
                                                            <Form.Control
                                                                name="first_name"
                                                                value={values.first_name}
                                                                onChange={handleChange}
                                                                isInvalid={touched.first_name && !!errors.first_name}/>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.first_name}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-md-12 col-12">
                                                        <Form.Group controlId="validationFormik-middle_name">
                                                            <Form.Label>{"Шариф"}</Form.Label>
                                                            <Form.Control
                                                                name="middle_name"
                                                                value={values.middle_name}
                                                                onChange={handleChange}
                                                                isInvalid={touched.middle_name && !!errors.middle_name}/>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.middle_name}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-footer d-flex justify-content-end">
                                                <button type="submit" className="btn btn-primary">Сақлаш</button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProfileContainer;
