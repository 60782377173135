import React from 'react';
import classNames from 'classnames';
import Avatar from "./Avatar";
import {isToday, isValid} from "date-fns";
import ModalImage from "react-modal-image";
import Const from "../core/Const";
import moment from "moment";


const MessageItem = ({item, user, attachments, createDialog, updateMessage}) => {

    let isSelf = "";


    if (item.user) {
        if (item.user.id === user.id) {
            isSelf = "self";
        }
    }

    const linkify = inputText => {
        const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
        return inputText.replace(reg, "<a target='_blank' href='$1$2'>$1$2</a>");
    }

    const getMessageTime = date => {

        date = new Date(date);

        if (!isValid(date)) {
            return ""
        }
        if (isToday(date)) {
            return moment(date).format('HH:mm');
        } else {
            return moment(date).format('d.MM.Y HH:mm');
        }
    };

    const bytesToSize = bytes => {
        let sizes = ['Byte', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    const renderAttachment = item => {

        let extension = item.filename.split('.').pop().toLowerCase();
        let image = false;

        if (extension === "png" || extension === "jpg" || extension === "jpeg" || extension === "bmp") {
            image = true;
        }

        let url = Const.URL + "/" + item.url;

        return (
            <div className="document" key={item.id}
            >
                {
                    image ?
                        <div className="message-img">
                            <ModalImage
                                small={url}
                                large={url}
                                alt={item.filename}
                            />
                        </div>
                        :
                        <a href={url} className="btn btn-primary btn-icon rounded-circle text-light mr-2" target={"_blank"}>
                            <svg className="hw-24" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"/>
                            </svg>
                        </a>
                }

                <div className="document-body">
                    <h6>
                        <div className="text-reset" title={item.filename}>{item.filename}</div>
                    </h6>

                    <ul className="list-inline small mb-0">
                        <li className="list-inline-item">
                            <span className="text-muted">{bytesToSize(item.size)}</span>
                        </li>
                        <li className="list-inline-item">
                            <span className="text-muted text-uppercase">{extension}</span>
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    if (item.text.length === 0) {
        return "";
    }

    return (
        <>
            <div className={classNames("message", isSelf)}>
                <div className="message-wrapper">
                    <div className="message-content">

                        {attachments && (
                            <div className="message__attachments">
                                {attachments.map(item => renderAttachment(item))}
                            </div>
                        )}

                        <div dangerouslySetInnerHTML={{__html: linkify(item.text)}}/>

                    </div>
                </div>
                <div className="message-options">
                    <Avatar user={item.user} type="sm"/>
                    <span className="message-date">{getMessageTime(item.created_at)}</span>
                    {
                        isSelf !== "self" ?
                            <>
                                <div className="dropdown-plus" onClick={() => createDialog(item)}>
                                    Янги диалог
                                </div>
                            </>
                            :
                            <div className="dropdown-plus" onClick={() => updateMessage(item)}>
                                Ўзгартириш
                            </div>
                    }
                </div>
            </div>
        </>
    );
};

export default MessageItem;
