import React from "react";
import {Form, Modal} from "react-bootstrap";
import {Formik} from "formik";
import Yup from "../utils/YupLocale";
import {dialogsActions} from "../redux/actions";
import {connect} from "react-redux";
import find from "lodash/find";

const ChildModal = ({modelShow, modelClose, dialogID, categories, dialogCategory}) => {

    const close = () => "";

    const schema = Yup.object({
        category_id: Yup.string(),
    });

    const handleSubmit = (values, {resetForm}) => {
        dialogsActions.closeDialog(dialogID, values.category_id);
        modelClose();
        resetForm();
    };

    return (
        <>
            <Modal
                show={modelShow} onHide={close}
            >
                <Modal.Body>
                    <Formik
                        validationSchema={schema}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                        initialValues={{
                            category_id: 0,
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              values,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="validationFormik-close">
                                    <Form.Control
                                        as="select"
                                        className="form-control"
                                        name={"category_id"}
                                        onChange={handleChange}
                                        value={values.category_id}
                                    >
                                        <option value="0" disabled={true} key={0}>Танланг</option>
                                        {
                                            categories.filter(e => e.parent_id === dialogCategory).map(e => <option key={Math.random()} value={e.id}>{e.name_uz}</option>)
                                        }
                                    </Form.Control>
                                </Form.Group>
                                <button onSubmit={() => handleSubmit} type="submit" className="btn btn-success">
                                    Диалогни ёпиш
                                </button>
                                <button className="btn btn-secondary ml-4" onClick={modelClose} type="button">
                                    Ёпиш
                                </button>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default connect(
    ({dialogs, user}) => ({
        currentDialog: find(dialogs.items, {id: parseInt(dialogs.currentDialogId)}),
        user: user.data,
        categories: dialogs.categories
    }),
    dialogsActions,
)(ChildModal);
