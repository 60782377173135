import React, {useState} from 'react';
import Avatar from "./Avatar";
import {axios} from "../core";

const Sidebar = ({user}) => {

    let inputReference = React.createRef();
    const [data, setData] = useState(user);

    const fileUploadInputChange = (e) => {
        let file = e.target.files[0];

        let form = new FormData();
        form.append("file", file);

        axios.put("/user/change/avatar/"+user.id, form)
            .then(() => {
                window.location = "/profile";
            })
            .catch(e => {
                console.log(e.toString());
            })
    }

    const fileUploadAction = () => {
        inputReference.current.click()
    }

    const handleLogout = () => {
        localStorage.removeItem("token");
        window.location = "/";
    }

    return (
        <aside className="sidebar">
            <div className="tab-content">
                <div className="tab-pane active" id="profile-content">
                    <div className="d-flex flex-column h-100">
                        <div className="hide-scrollbar">

                            <div className="sidebar-header sticky-top p-2 mb-3">
                                <h5 className="font-weight-semibold">Профиль</h5>
                                <p className="text-muted mb-0">Асосий маълумотлар</p>
                            </div>


                            <div className="container-xl">
                                <div className="row">
                                    <div className="col">


                                        <div className="card card-body card-bg-5">


                                            <div className="d-flex flex-column align-items-center">
                                                <div onClick={() => fileUploadAction()} role="button">
                                                    <input type="file" ref={inputReference} hidden onChange={(e) => fileUploadInputChange(e)}/>
                                                    <Avatar user={data} type={"lg"}/>
                                                </div>
                                                <div className="d-flex flex-column align-items-center mt-2">
                                                    <h5>{user.last_name}</h5>
                                                </div>

                                                <div className="d-flex">
                                                    <button className="btn btn-outline-default mx-1" type="button" onClick={handleLogout}>
                                                        <svg className="hw-18 d-none d-sm-inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"/>
                                                        </svg>
                                                        <span>Чиқиш</span>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="card mt-3">


                                            <ul className="list-group list-group-flush">


                                                <li className="list-group-item py-2">
                                                    <div className="media align-items-center">
                                                        <div className="media-body">
                                                            <p className="small text-muted mb-0">Email</p>
                                                            <p className="mb-0">{user.email}</p>
                                                        </div>


                                                        <svg className="text-muted hw-20 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                                                        </svg>


                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </aside>
    );
};

export default Sidebar;
