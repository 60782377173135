import React, {useEffect} from 'react';
import Navigation from "../components/Navigation";
import Messages from "../components/Messages";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import Dialogs from "../components/Dialogs";
import {dialogsActions} from "../redux/actions";

const Home = ({fetchCategories, setCurrentDialogId, user, location}) => {

    useEffect(() => {
        const {pathname} = location;
        const dialogId = pathname.split('/').pop();
        setCurrentDialogId(dialogId);
    }, [location.pathname]);

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <>
            <div className="main-layout">
                <Navigation/>
                <Dialogs/>
                <Messages user={user}/>
            </div>
        </>
    );
};

export default withRouter(
    connect(
        ({user, categories}) => ({user: user.data, categories: categories}),
        dialogsActions
    )(Home),
);
